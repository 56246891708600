import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Vorbereiten einer Ausschreibung in Business Central",
  "description": "So bereiten Sie eine Ausschreibung in Business Central für das Karriereportal vor",
  "author": "Jochen Ritscher",
  "categories": ["businesscentral"],
  "date": "2021-08-13T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Erstellen Sie eine neue oder öffnen Sie eine bestehende Ausschreibung um diese in Ihr Karriereportal zu schalten.`}</p>
    <p>{`Die Karte "Ausschreibungskarte" enthält die Inforegister “Portal” und “Textzeilen” um Informationen speziell für die Schaltung auf dem Karriereportal zu hinterlegen.`}</p>
    <img src="/images/businesscentral/ausschreibungskarte-1.png" alt="ausschreibungskarte" style={{
      "width": "100%"
    }} />
    <p>{`Inforegister Portal`}</p>
    <p>{`Das Inforegister "Portal" beinhaltet die Datenfelder, die die vakante Stelle beschreiben.`}</p>
    <p>{`Folgende Daten werden gewöhnlich in das Karriereportal übertragen`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Titel
Ein aussagekräftiger Anzeigentitel ist wichtig, damit die richtigen Bewerber Ihre Anzeige finden. Verwenden Sie geläufige und möglichst präzise Berufsbezeichnungen.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Untertitel
In den Untertitel können Sie zusätzliche Informationen aufnehmen, die den Titel unnötig verlängern würden. Beispiele: "unbefristet", "30h/Woche", "Raum Bayern". Er wird in der Stellenanzeige direkt unter dem Titel angezeigt.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Kopftext
Im Kopftext beschreiben Sie kurz Ihr Unternehmen und können ein e Einleitung zur Stellenanzeige angeben`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Fußtext
Im Fußtext können Sie den Kandidaten zur Bewerbung motivieren und nützliche Informationen zum Bewerbungsprozess mitteilen.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Layout-Vorlage
Die von Ihrer Marketingabteilung vorgegebenen Layoutvorlagen können hier ausgewählt werden`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Firma
Name der ausschreibenden Firma, falls abweichend`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Abteilung
Titel der ausschreibenden Abteilung, falls abweichend`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Standort
Ausschreibende Niederlassung`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Einsatzort
falls vom Standort abweichend: Ort des Einsatzes`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Branche
Branche oder Stellenkategorie`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Karrierelevel
Vollzeit, Teilzeit, Ausbildung, Führungskraft, Einsteiger, ...`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Gehalt ab
Mindestgehaltsangabe, z.B. in Österreich gesetzlich vorgeschrieben`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Externe ID
Optional: Ihre Referenz`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Recruiter
Zuständiger Mitarbeiter im Recruiting inkl. Kontaktdaten`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Online
Ausschreibung wird auf dem Portal veröffentlicht`}</p>
      </li>
    </ul>
    <p>{`Inforegister Textzeilen`}</p>
    <img src="/images/businesscentral/ausschreibungskarte-2.png" alt="ausschreibungskarte" style={{
      "width": "100%"
    }} />
    <p>{`In den Textzeilen geben Sie Zeilenweise die anzuzeigenden Textbausteien ein:`}</p>
    <p>{`Wählen Sie den Abschnitt in dem der Text angezeigt werden soll`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Ihre Aufgaben
Beschreiben Sie präzise die vom Bewerber zu erfüllenden Aufgaben`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ihr Profil
Beschreiben Sie „zwingende“ und „wünschenswerte“ Anforderungen an Ihre Bewerber & das gewünschte Kandidatenprofil`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Was wir bieten
Präsentieren Sie sich als attraktiver Arbeitgeber. Erwähnen Sie z. B. familienfreundliche Arbeitsregelungen. Nutzen Sie möglichst präzise und konkrete Formulierungen.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Textblock
Weitere Platzhalter für Textbausteine Ihrer Wahl`}</p>
      </li>
    </ul>
    <p>{`Geben Sie den Text in der Spalte "Text" ein. Achten Sie hierbei darauf, keine Sonderzeichen aus Drittsystemen in das Feld zu kopieren.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      